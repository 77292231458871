import $ from 'jquery'
import { ScanQrCode } from './service/ScanQrCode'
import { WS } from './service/Ws'
import { useThirdOrderData } from './helper/useThirdOrderData'
import { useZergOrderData } from './helper/useZergOrderData'

const uri = new URL(window.location.href)
const staff_id = uri.searchParams.get('staff_id')
if (!staff_id) {
  alert('staff_id 不能为空')
  throw new Error('staff_id 不能为空')
}

let orderNo: string | null = null

// 黄色的扫描线
const $scanner = $('.scanner')
const $orderPopup = $('#order-detail')
const $content = $('#content')
const $clsoeBtn = $orderPopup.find('#close-btn')
const $postOrderBtn = $orderPopup.find('#order-post')

/**
 * 停止扫描
 */
const stopScan = () => {
  scanQrCode.stop()
  $scanner.css('animationPlayState', 'paused')
}

/**
 * 继续扫描
 */
const startScan = () => {
  scanQrCode.start()
  $scanner.css('animationPlayState', 'running')
}

// 扫码客户端
const scanQrCode = new ScanQrCode('reader')
const ws = new WS(staff_id)
scanQrCode.start()

/** 扫码成功时触发 */
scanQrCode.scanQrHook.on((result) => {
  stopScan()

  console.log('扫码成功', result)
  ws.getOrderDetail(result)
    .then(() => {
      orderNo = result
      $content.empty()
      $content.append(`<p>正在加载:${result}</p>`)
      $orderPopup.show()
    })
    .catch((err) => alert(err.message))
})

// 显示第三方订单
ws.hook.showThirdOrder.on((data) => {
  useThirdOrderData($content, data)
})

// 显示后台订单
ws.hook.showZergOrder.on((data) => {
  useZergOrderData($content, data)
})

// 后台正在打印
ws.hook.orderPost.on((data) => {
  alert(data.message)
})

// 点击关闭弹窗
$clsoeBtn.on('click', () => {
  startScan() // 继续扫描
  orderNo = null
  $orderPopup.hide()
})

// 点击订单发货
$postOrderBtn.on('click', () => {
  ws.orderPost(orderNo)
    .then((res) => {
      console.log(res)
    })
    .catch((err) => alert(err.message))
})
