/**
 * 展示第三方数据
 * @param third
 */
export const useThirdOrderData = (
  $content: JQuery<HTMLElement>,
  third: any
) => {
  $content.empty()
  console.log(third)

  // 订单信息
  const order = `
<div class="bg-white rounded-lg shadow-md p-3 mb-3 ">
    <h2 class="text-base font-semibold mb-1">订单信息</h2>
    <div class="text-sm leading-6">
        <p>订单状态: ${third.make_status_text}</p>
        <p>第三方订单号: ${third.third_no}</p>
        <p>后台订单号: ${third.order_exchange_no}</p>
        <p>实付金额: ${third.payment_price}</p>
        <p>渠道号: ${third.channel_no}</p>
        <p>备注：${third.note}</p>
    </div>
</div>
`
  $content.append(order)

  // 地址信息
  const address = `
<div class="bg-white rounded-lg shadow-md p-3 mb-3 ">
    <h2 class="text-base font-semibold mb-1">地址信息</h2>
    <div class="text-sm leading-6">
        <p>收货人: ${third.contact_name}</p>
        <p>电话: ${third.contact_phone}</p>
        <p>邮编: ${third.zip}</p>
        <p>地址: ${third.province} ${third.city} ${third.district} ${third.address}</p>
    </div>
</div>
`
  $content.append(address)

  // 循环输出商品
  $content.append(
    `<h2 class="text-xl font-bold mb-3">下单商品数量 *${third.goods.length}</h2>`
  )

  const goods = third.goods.map((good: any) => {
    const justify =
      good.isSingleSided == '1' ? 'justify-center' : 'justify-between'

    const cols = good.isSingleSided == '1' ? 'grid-cols-1' : 'grid-cols-2'

    return `
<div class="bg-white rounded-lg shadow-md p-3 mb-3 ">
    <h2 class="text-base font-semibold mb-1">${good.goods_name}-${good.sku_name}</h2>
    <div class="text-sm leading-6 flex flex-wrap">
        <p class="w-1/2">购买总数量: ${good.goods_num}</p>
        <p class="w-1/2">商品厚度: ${good.thickness}(mm)</p>
        <p class="w-1/2">商品宽度: ${good.physical_width}(mm)</p>
        <p class="w-1/2">商品高度: ${good.physical_height}(mm)</p>
         <p class="w-1/2">出血线范围: ${good.bleLine_range}</p>
        <p class="w-1/2">边角: ${['', '直角', '圆角'][good.corner] ?? '/'}</p>
        <p class="w-1/2">单双面打印: ${good.isSingleSided == 1 ? '单面' : '双面'}</p>
    </div>
    ${good.custom
      .map(
        (custom: any) => `
<div class="bg-gray-100 p-3 rounded-sm -mx-3 border-b">
    <div class="font-semibold">数量：${custom.num}</div>
    <div class="grid ${cols} gap-4">
        <div class="flex ${justify}">
            <img class="${custom.front_other_img ? 'w-[47.5%]' : ''}" src="${third.cdnurl}${custom.fimg_url}"/>
            ${custom.front_other_img ? `<img class="w-[47.5%]" src="${third.cdnurl}${custom.front_other_img}"/>` : ''}
        </div>
        ${
          good.isSingleSided == '1'
            ? ''
            : `
        <div class="flex ${good.isSingleSided == '1' ? 'justify-center' : 'justify-between'}">
            <img  class="${custom.back_othert_img ? 'w-[47.5%]' : ''}" src="${third.cdnurl}${custom.bimg_url}"/>
            ${custom.back_othert_img ? `<img class="w-[47.5%]" src="${third.cdnurl}${custom.back_othert_img}"/>` : ''}
        </div>
        `
        }
    </div>
</div>
`
      )
      .join('')}
</div>
`
  })

  $content.append(goods)
}
