import { Html5QrcodeResult, Html5Qrcode } from 'html5-qrcode'
import { SyncHook } from './SyncHook'
/**
 * 二维码扫码函数
 */
export class ScanQrCode {
  /**
   * 扫码成功触发的方式
   */
  protected qrCodeSuccessCallback(
    decodedText: string,
    result: Html5QrcodeResult
  ) {
    this.scanQrHook.notify(decodedText, result)
  }

  /**
   * 扫码失败触发
   */
  protected onScanFailure(...args: any[]) {
    // console.log('扫码失败', args)
  }

  /**
   * 扫码停止
   */
  stop() {
    this.html5QrCode.stop()
  }

  /**
   * 开始扫码
   * @param selector
   * @param config
   */
  start() {
    /**
     * 开启后置摄像头，详见 https://scanapp.org/html5-qrcode-docs/docs/intro
     */
    this.html5QrCode.start(
      { facingMode: 'environment' },
      this.config,
      (decodedText, result) => this.qrCodeSuccessCallback(decodedText, result),
      (err) => this.onScanFailure(err)
    )
  }

  constructor(
    protected readonly selector: string,
    protected readonly config = { fps: 10, qrbox: { width: 250, height: 250 } }
  ) {
    const html5QrCode = new Html5Qrcode(selector)
    this.html5QrCode = html5QrCode
  }

  public readonly scanQrHook = new SyncHook<[string, Html5QrcodeResult]>()
  public html5QrCode
}
