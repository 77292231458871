import { SyncHook } from './SyncHook'

// 最小重连的间隔
const minInterval = 15 * 1000

/**
 * 链接后台的长链接
 */
export class WS {
  /** 链接的webscoket */
  protected with(ws: WebSocket) {
    // 链接成功时
    ws.addEventListener('open', () => {
      this.interval = minInterval
      const intervalFn = () => {
        clearTimeout(this.timeoutIndex)
        ws.send('{"type": "ping"}')
        this.timeoutIndex = setTimeout(intervalFn, 30000)
      }
      intervalFn()
    })

    // 收到消息时
    ws.addEventListener('message', (event: MessageEvent) => {
      try {
        const res = JSON.parse(event.data)
        console.log('res', res)
        const { type, sessionId, data } = res
        switch (type) {
          case 'connected':
            this.sessionId = sessionId
            this.hook.link.notify(sessionId)
            break
          case 'order-detail':
            this.showOrder(data)
            break
          case 'order-post':
            this.hook.orderPost.notify(data)
            break
        }
      } catch {}
    })

    // 当链接关闭时
    ws.addEventListener('close', () => {
      setTimeout(
        () => {
          this.relink()
        },
        (this.interval *= 2)
      )
    })
  }

  /**
   * 重新链接
   */
  protected relink() {
    clearTimeout(this.timeoutIndex)
    const ws = new WebSocket(this.url)
    this.with(ws)
    this.ws = ws
  }

  /**
   * 显示订单详情
   */
  protected showOrder(data: any) {
    if (data.order_type == '10') {
      this.hook.showZergOrder.notify(data)
    }
    if (data.order_type == '20') {
      this.hook.showThirdOrder.notify(data)
    }
  }

  /**
   * 去获取订单详情
   */
  async getOrderDetail(orderNo: string) {
    if (!this.sessionId) throw new Error('已断开链接')
    const postData = {
      data: { orderNo },
      sessionId: this.sessionId,
      type: 'order-detail',
    }

    const res = await fetch(
      `//${this.baseUrl}/terminal/ask?staff_id=${this.staff_id}`,
      {
        method: 'POST',
        body: JSON.stringify(postData),
      }
    )

    const resData = await res.json()
    if (res.status !== 200) throw new Error(resData.message)

    return resData
  }

  /**
   * 订单发货
   */
  async orderPost(orderNo: string | null) {
    if (!orderNo) throw new Error('请先扫码获取订单号')
    if (!this.sessionId) throw new Error('已断开链接')
    const postData = {
      data: { orderNo },
      sessionId: this.sessionId,
      type: 'order-post',
    }

    const res = await fetch(
      `//${this.baseUrl}/terminal/ask?staff_id=${this.staff_id}`,
      {
        method: 'POST',
        body: JSON.stringify(postData),
      }
    )

    const resData = await res.json()
    if (res.status !== 200) throw new Error(resData.message)

    return resData
  }

  constructor(protected readonly staff_id: string) {
    this.url = `wss://${this.baseUrl}/terminal/touch?staff_id=${staff_id}`
    this.relink()
  }

  protected baseUrl = 'socket.ato-z.icu'
  protected interval = minInterval
  protected timeoutIndex!: ReturnType<typeof setTimeout>
  protected ws!: WebSocket
  protected url
  protected sessionId?: string
  public readonly hook = {
    link: new SyncHook<[string]>(),
    showZergOrder: new SyncHook<[any]>(),
    showThirdOrder: new SyncHook<[any]>(),
    orderPost: new SyncHook<[{ message: string }]>(),
  }
}
